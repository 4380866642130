<template>
	<w-data-table
		:headers="headers"
		:loading="loading"
		:items="value"
		:no-data-text="noDataText"
		:pagination.sync="paginationCopy"
		:rows-per-page-items="rowsPerPageItems"
		:total-items="totalItems"
	>
		<template v-slot:headers="props">
			<tr>
				<slot v-for="(header, index) in props.headers" :name="`header-cell-${index}`">
					<th :key="index" :class="index === 0 ? 'text-xs-left' : ''">
						{{ header.text }}
					</th>
				</slot>
			</tr>
		</template>
		<template v-slot:items="props">
			<slot name="items" :row="props.item"></slot>
		</template>
		<template v-if="noDataText && !loading" v-slot:no-data>
			<v-alert color="warning" icon="warning" :value="true">
				{{ noDataText }}
			</v-alert>
		</template>
	</w-data-table>
</template>

<script>
export default {
	name: 'WMatrix',
	props: {
		headers: {
			required: false,
			type: Array,
			default: () => []
		},
		loading: {
			required: false,
			type: Boolean,
			default: () => false
		},
		noDataText: {
			required: false,
			type: String,
			default: () => null
		},
		pagination: {
			required: false,
			type: Object,
			default: () => ({})
		},
		totalItems: {
			required: false,
			type: Number,
			default: () => undefined
		},
		value: {
			required: true,
			type: Array
		}
	},
	computed: {
		paginationCopy: {
			get: function () {
				return { ...this.pagination }
			},
			set: function (val) {
				this.$emit('update:pagination', val)
			}
		},
		rowsPerPageItems: function () {
			let result = [5, 15, 25, 50]
			if (!isNaN(this.pagination.rowsPerPage) && this.pagination.rowsPerPage < 50) {
				result.push(this.pagination.rowsPerPage)
				result.sort((a, b) => a - b)
			}
			return result
		}
	}
}
</script>
